import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
const AboutUsPage = ({ pageId }) => {
    const [pageData, setPageData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPageData = async () => {
            try {
                const response = await axios.get(`https://staging5.mumandyou.com/wp-json/wp/v2/pages/11?_embed`);
                setPageData(response.data);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching page data:', error);
                setLoading(false); // Set loading to false if an error occurs
            }
        };

        fetchPageData();
    }, [pageId]); // Trigger effect when pageId prop changes

    if (loading) return <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>; // Display loading message while data is being fetched

    if (!pageData) return <div>Error: Unable to fetch page data.</div>; // Display error message if pageData is null

    return (
        <main>
		<section className="breadcrumb-section">
	<div className="bg-overlay bg-gradient-overlay"></div>
	<div className="container">
		<div className="row">
			<div className="col-lg-7">
				<div className="breadcrumb-heading">
					<h1>{pageData.acf.short_title}</h1>
					<p>{pageData.acf.short_sub_title}</p>
					<a href="/request-free-audit" className="request-btn">Request Demo</a>
				</div>
			</div>
		</div>
	</div>
	<div className="breadcrumb-feature-img">
	{pageData._embedded && pageData._embedded['wp:featuredmedia'] && (
                <img src={pageData._embedded['wp:featuredmedia'][0].source_url} alt={pageData.title.rendered} />
            )}
	</div>
</section>
           
            
           <section className="page-about section-padding">
            
            <div className="container">
				<div className="row g-4" dangerouslySetInnerHTML={{ __html: pageData.content.rendered }} />
				</div>
	</section>
			<section className="content-section-bottom section-padding">
<div className="container">
<div className="row"><div className="col-lg-10 offset-lg-1" dangerouslySetInnerHTML={{ __html: pageData.acf.extra_content }} />

			</div>
		</div>
	</section>
        </main>
    );
};


export default AboutUsPage;
