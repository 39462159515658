import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import wordpressApiUrl from '../../config';
import Banner from './Banner';
import Clients from './Clients';
import Powerhouse from './Powerhouse';
import BrandDock from './BrandDock';
import Portfolio from './Portfolio';
import Perks from './Perks';
import Mastery from './Mastery';
import Testimonials from './Testimonials';
const HomePage = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${wordpressApiUrl}/posts?_embed&per_page=4`);
        const postData = await response.json();
        setBlogPosts(postData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPosts();
  }, []);

  return (
  
    <main>
	<Banner/>	
	<Clients/>
	<Powerhouse/>
	<BrandDock/>
	<Portfolio/>
	<Perks/>
	<Mastery/>
	<Testimonials/>
	</main>
  );
};

export default HomePage;
