import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { FaEnvelope } from 'react-icons/fa';
import { FaPhoneVolume } from 'react-icons/fa';
import { FaLocationArrow } from 'react-icons/fa';
import arrow from '../images/arrow.png';
import linkedin from '../images/linkedin.png';
import facebook from '../images/facebook.png';
import instagram from '../images/instagram.png';
import ContactForm from './ContactForm';
const ContactUsPage = ({ pageId }) => {
    const [pageData, setPageData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPageData = async () => {
            try {
                const response = await axios.get(`https://staging5.mumandyou.com/wp-json/wp/v2/pages/20?_embed`);
                setPageData(response.data);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching page data:', error);
                setLoading(false); // Set loading to false if an error occurs
            }
        };

        fetchPageData();
    }, [pageId]); // Trigger effect when pageId prop changes

    if (loading) return <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>; // Display loading message while data is being fetched

    if (!pageData) return <div>Error: Unable to fetch page data.</div>; // Display error message if pageData is null

    return (
        <main>
		<section className="breadcrumb-section">
	<div className="bg-overlay bg-gradient-overlay"></div>
	<div className="container">
		<div className="row">
			<div className="col-lg-7">
				<div className="breadcrumb-heading">
					<h1>{pageData.acf.short_title}</h1>
					<p>{pageData.acf.short_sub_title}</p>
					<p><a href="mailto:info@ezcommerce.in"><FaEnvelope /> info@ezcommerce.in</a></p>
<p><a href="tel:+919540797657"><FaPhoneVolume /> +91 95407 97657</a></p>

				</div>
			</div>
		</div>
	</div>
	<div className="breadcrumb-feature-img">
	{pageData._embedded && pageData._embedded['wp:featuredmedia'] && (
                <img src={pageData._embedded['wp:featuredmedia'][0].source_url} alt={pageData.title.rendered} />
            )}
	</div>
</section>
           
            
           <section className="contact section-padding">
<div className="container">
<div className="row g-4">
<div className="col-lg-6">
<div className="content bg-image">
<h2>Get connected with our Experts</h2>
<p>Get in touch and get started today. We're ready!</p>
<div className="arry"><img src={arrow} alt="" /></div>
<ul className="formlist">
<li><a href="#" target="_blank"><FaLocationArrow /> 91 Springboard Business Hub Pvt Ltd Noida Sector 2, India</a>
</li>
<li><a href="#" target="_blank"><FaLocationArrow /> 138, Westlink House | 981 Great West Road Brentford, TW8 9DN</a>
</li>


</ul>
<h4 className="color-white py-3">Follow Us</h4>
<div className="social-icon">
<a href="https://www.linkedin.com/company/ezcommerceindia" target="_blank" rel="nofollow"><img src={linkedin} alt="" /></a>
<a href="https://www.facebook.com/EZCommerceHQ/"  target="_blank" rel="nofollow"><img src={facebook} alt="" /></a>
<a href="#"><img src={instagram} alt="" /></a>
</div>
</div>
</div>
<div className="col-lg-6">
<div className="form-area">
<ContactForm/>
</div>
</div>
</div>
</div>
</section>
	<div className="google-map" dangerouslySetInnerHTML={{ __html: pageData.acf.extra_content }} />
        </main>
    );
};


export default ContactUsPage;
