import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import wordpressApiUrl from '../config';
import checkmark from '../images/check-mark.png';
const BlogPostDetails = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`${wordpressApiUrl}/posts/${postId}?_embed`); // Fetch post with embedded data
        const postData = await response.json();
        setPost(postData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPost();
  }, [postId]);

  if (!post) {
    return <div>Loading...</div>;
  }

  // Extract featured image URL if available
  let featuredImageUrl = null;
  if (post._embedded && post._embedded['wp:featuredmedia']) {
    featuredImageUrl = post._embedded['wp:featuredmedia'][0].source_url;
  }

  return (
  <main>
    <section className="breadcrumb-section">
	<div className="bg-overlay bg-gradient-overlay"></div>
	<div className="container">
		<div className="row">
			<div className="col-lg-7">
				<div className="breadcrumb-heading">
					<h1>{post.title.rendered}</h1>
					<a href="/request-free-audit" className="request-btn">Request Demo</a>
				</div>
			</div>
		</div>
	</div>
	
</section>
     <section className="page-about page-service-single  section-padding">
            <div className="container">
			  <div className="row">
            <div className="col-xl-10 col-lg-10 content-area m-xl-auto">
                       
                            <div className="post prt-blog-single clearfix">
                                
                                <div className="prt-blog-single-content">
                                   <div className="image mb-30">
								   {post._embedded && post._embedded['wp:featuredmedia'] && (
              <img
                src={post._embedded['wp:featuredmedia'][0].source_url}
                alt={post.title.rendered}
                className="card-img-top"
              />
            )}
                        </div>
                                    <div className="entry-content">
                                        <div className="prt-box-desc-text">
                                     <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />  
									 
				     </div>
                            </div>
							</div>
                        </div> 
						</div>
						</div>
						
						</div>
        </section>
		
		<section className="service-style-section clearfix">
<div className="container">
<div className="row">
<div className="col-lg-12">
<div className="sub-bg spacing-27">
<div className="section-title mb-4">
<h4>Here are some of the services that we provide:</h4>
</div>
<div className="row">
<div className="col-lg-6">
<ul className="prt-list prt-list-style-icon style6">
<li><a href="/branding"><img src={checkmark} alt="" /><span className="prt-list-li-content">Branding</span></a>
</li>
<li><a href="/amazon-marketing-services"><img src={checkmark} alt="" /><span className="prt-list-li-content">Amazon Marketing Services</span></a>
</li>
<li><a href="/performance-marketing"><img src={checkmark} alt="" /><span className="prt-list-li-content">Performance Marketing</span></a>
</li>
</ul>
</div>
<div className="col-lg-6">
<ul className="prt-list prt-list-style-icon style6">
<li><a href="/data-analytics-automation"><img src={checkmark} alt="" /><span className="prt-list-li-content">Data Analytics & Automation</span></a>
</li>
<li><a href="/testing"><img src={checkmark} alt="" /><span className="prt-list-li-content">Testing</span></a>
</li>
<li><a href="/web-development"><img src={checkmark} alt="" /><span className="prt-list-li-content">Web Development</span></a>
</li>
</ul>
</div>

</div>
</div>
</div>
</div>
</div>
</section>
		</main>
  );
};

export default BlogPostDetails;
