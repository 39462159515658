import React from 'react';
import shape1 from '../images/shape1.png';
import shape2 from '../images/shape2.png';
const FirstPageDetails = ({ page }) => {
  return (
  <main>
  
   <section className="breadcrumb-section">
	<div className="bg-overlay bg-gradient-overlay"></div>
	<div className="container">
		<div className="row">
			<div className="col-lg-7">
				<div className="breadcrumb-heading">
					<h1>{page.acf.short_title}</h1>
					<p>{page.acf.short_sub_title}</p>
					<a href="/request-free-audit" className="request-btn">Request Demo</a>
				</div>
			</div>
		</div>
	</div>
	<div className="breadcrumb-feature-img">
	{page._embedded && page._embedded['wp:featuredmedia'] && (
                <img src={page._embedded['wp:featuredmedia'][0].source_url} alt={page.title.rendered} />
            )}
	</div>
</section>
	<section className="page-about section-padding">
            <div className="shape1 d-none d-lg-block float-bob-y">
                <img src={shape1} alt="" />
            </div>
            <div className="shape2 d-none d-lg-block float-bob-y">
                <img src={shape2} alt="" />
            </div>
            <div className="container">
				<div className="row g-4" dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
				</div>
	</section>
			<section className="service-style-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" dangerouslySetInnerHTML={{ __html: page.acf.extra_content }} />

			</div>
		</div>
	</section>
	</main>
  );
};

export default FirstPageDetails;
