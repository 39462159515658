import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import wordpressApiUrl from '../config';
import blogimg from '../images/blog-img.png';
const BlogPost = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${wordpressApiUrl}/posts?_embed&per_page=6`);
        const postData = await response.json();
        setBlogPosts(postData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPosts();
  }, []);

  return (
      <main>
        <section className="breadcrumb-section">
	<div className="bg-overlay bg-gradient-overlay"></div>
	<div className="container">
		<div className="row">
			<div className="col-lg-7">
				<div className="breadcrumb-heading">
					<h1>Blog</h1>
					<p>Opinions, tips, and latest news on software, technology, design, and business for innovators</p>
					<a href="/request-free-audit" className="request-btn">Request Demo</a>
				</div>
			</div>
		</div>
	</div>
	<div className="breadcrumb-feature-img">
	<img src={blogimg} alt="" />
	</div>
</section>
      <section className="blog-three section-padding">
            <div className="container">
			<div className="section-header text-center m-40">
                  <h2 className="wow fadeInUp">Blog</h2>
				  <p>We are a team of dynamic, Specialized Professionals</p>
                </div>
                <div className="row">
       {blogPosts.map(post => (
       <div className="col-md-6 col-xl-4 wow fadeInLeft mb-4" data-wow-duration="1.5s" data-wow-delay=".5s" key={post.id}>
<div className="item">
<div className="image">
{post._embedded && post._embedded['wp:featuredmedia'] && (
              <img
                src={post._embedded['wp:featuredmedia'][0].source_url}
                alt={post.title.rendered}
                className="card-img-top"
              />
            )}
<div className="content">
<h3>{post.title.rendered}</h3>
<div className="info">
<p>{post.excerpt.rendered.replace(/(<([^>]+)>)/gi, "").substring(0, 100)}</p>
</div>
</div>
</div>
<div className="content-up">
<h3>
<Link to={`/post/${post.id}`}>{post.title.rendered}</Link>
</h3>
<p>{post.excerpt.rendered.replace(/(<([^>]+)>)/gi, "").substring(0, 100)}</p>
<Link to={`/post/${post.id}`} className="btn-two">View More</Link>
</div>
</div>
</div>
   ))}
	</div>
            </div>
        </section>
	<section className="service-bgimage-section">
	<div className="container">
		<div className="row">
			<div className="col-lg-7">
					<div className="title-header">
						<h2>We can create any custom or universal features needed for smooth customer journeys.</h2>
					</div>
			</div>
			<div className="col-lg-5 align-self-center">
				<div className="title-side-text">
					<h3>Hello, We're EZ Commerce </h3>
					<p> Our collaborative technologies and strategies strongly stand by offerings, which are aligned with today and the changes of tomorrow. Innovations are some value additions that lead us to design digital future and deliver an amazing experience, which you always prefer for claiming leadership and seamless going in irrespective of industries. </p>
					<a className="btn-getstarted mt-15" href="/request-free-audit">Get Started</a>
				</div>
			</div>
		</div>
	</div>
</section>
    </main>
  );
};

export default BlogPost;
